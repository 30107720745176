import React from "react";
//Page Components
import BannerSection from "../Components/BannerSection";
import GoalSection from "../Components/GoalSection";
import FaqSection from "../Components/FaqSection";
import AboutSection from "../Components/AboutSection";
import TeamSection from "../Components/TeamSection";
//Animations
import { motion } from "framer-motion";
import { pageAnimation } from "../animation";

const AboutUs = () => {
  return (
    <motion.div
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <BannerSection />
      <AboutSection />
      <GoalSection />
      <TeamSection />
      <FaqSection />
    </motion.div>
  );
};

export default AboutUs;
