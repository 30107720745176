//User Images
import caroline from "./img/team/caroline.jpg";
import castejon from "./img/team/castejon.jpg";
import Del from "./img/team/Del.jpg";
import karini from "./img/team/karimi.jpg";
import sofya from "./img/team/sofya.jpg";
import kapolka from "./img/team/kapolka.jpg";
import sabouni from "./img/sabouni.jpg";
import ghazaleh from "./img/ghazaleh.jpg";
import nazzal from "./img/nazzal.jpg";

import carolineBKG from "./img/backgrounds/caroline.jpg";
import castejonBKG from "./img/backgrounds/castejon.jpg";
import delBKG from "./img/backgrounds/del.jpg";
import karimiBKG from "./img/backgrounds/karimi.jpg";
import sofyaBKG from "./img/backgrounds/sofya.jpg";
import kapolkaBKG from "./img/kapolkaBKG.jpg";
import defaultBKG from "./img/backgroundGrid.svg";

import ProteinSection from "./Components/ResearchComponents/ResearchProteinFold";
import TDA from "./Components/ResearchComponents/TopologicalDataAnalysis";
import ProteinTDA from "./Components/ResearchComponents/ProteinTDA";
function userData() {
  return [
    {
      name: "Dr. Del Lucent",
      profileImage: `${Del}`,
      cardBackgroundImg: `${delBKG}`,
      about: [
        "Associate Professor Physics",
        "Associate Professor of Biology (by courtesy)",
        "Wilkes University Department of Electrical Engineering and Physics",
        "Wilkes University Department of Biology",
      ],
      contact: { email: "del.lucent@wilkes.edu", phone: "570-000-000" },
      projects: [
        {
          name: "Protein folding ",
          component: <ProteinSection />,
          users: [],
        },
        {
          name: "Topological Data Analysis",
          component: <TDA />,
          users: [],
        },
        {
          name: "Computational Biology/Biophysics",
          component: <ProteinTDA />,
          users: [],
        },
        {
          name: "Computational Drug Design",
          component: "{}",
          users: [],
        },
        {
          name: "Deep Learning and Data Science",
          component: "{}",
          users: [],
        },
      ],
    },

    {
      name: "Dr. Bobby Karimi",
      profileImage: `${karini}`,
      cardBackgroundImg: `${karimiBKG}`,
      about: [
        "Assistant Professor of Geology",
        " Wilkes University Department of Environmental Engineering, Earth Science, and Geology",
      ],
      contact: { email: "bobak.karimi@wilkes.edu", phone: "570-408-4698" },
      projects: [
        {
          name: "Geotectonics",
          component: {},
          users: [],
        },
        {
          name: "Geophysical Modeling",
          component: {},
          users: [],
        },
        {
          name: "Geographical Information Systems",
          component: {},
          users: [],
        },
        { name: "Topological Data Analysis", component: {}, users: [] },
      ],
    },
    {
      name: "Dr. Sofya Chepushtanova",
      profileImage: `${sofya}`,
      cardBackgroundImg: `${sofyaBKG}`,
      about: [
        "Associate Professor of Mathematics",
        "Wilkes University Department of Mathematics and Computer Science",
      ],
      contact: {
        email: "sofya.chepushtanova@wilkes.edu",
        phone: "570-408-4868",
      },
      projects: [
        {
          name: "Topological Data Analysis",
          component: <TDA />,
          users: [],
        },
        {
          name: "Computational Topology",
          component: <ProteinTDA />,
          users: [],
        },

        {
          name: "Hyperspectral imagery",
          component: "{}",
          users: [],
        },
        {
          name: "Machine Learning and Data Science",
          component: "{}",
          users: [],
        },
        {
          name: "Machine Learning of Biophysical Problems",
          component: "{}",
          users: [],
        },
      ],
    },
    {
      name: "Dr. Caroline Fotunato",
      profileImage: `${caroline}`,
      cardBackgroundImg: `${carolineBKG}`,
      about: [
        "Assistant Professor of Biology",
        "Widener University Department of Biology",
      ],
      contact: { email: "csfortunato@widener.edu", phone: "610-499-4029" },
      projects: [
        {
          name: "Microbial Ecology",
          component: {},
          users: [],
        },
        {
          name: "Marine Biology",
          component: {},
          users: [],
        },
        {
          name: "Next-gen Sequencing",
          component: {},
          users: [],
        },
        {
          name: "Bioinformatics",
          component: {},
          users: [],
        },
      ],
    },
    {
      name: "Dr. Henry Castejon",
      profileImage: `${castejon}`,
      cardBackgroundImg: `${castejonBKG}`,
      about: [
        "Professor of Chemistry",
        "Professor/Chair of Mechanical Engineering",
        "Wilkes University Department of Chemistry and Biochemistry",
        "Wilkes University Department of Mechanical Engineering and Engineering Management",
      ],
      contact: { email: "", phone: "" },
      projects: [
        { name: "Nonequilibrium Molecular Dynamics", component: {}, users: [] },

        { name: "Computational Chemistry", component: {}, users: [] },

        { name: "Computational Materials Science", component: {}, users: [] },

        { name: "Surface Chemistry", component: {}, users: [] },
        { name: "", component: {}, users: [] },
      ],
    },

    {
      name: "Dr. Anthony Kapolka",
      profileImage: `${kapolka}`,
      cardBackgroundImg: `${kapolkaBKG}`,
      about: [
        "Associate Professor of Computer Science",
        "Director of First-Year-Foundations Program",
        "Wilkes University Department of Mathematics and Computer Science",
      ],
      contact: { email: "anthony.kapolka@wilkes.edu", phone: "570-408-4847" },
      projects: [
        { name: "Data Science", component: {}, users: [] },
        { name: "Sentiment Analysis", component: {}, users: [] },
        {
          name: "Artificial Intelligence/ Machine Learning",
          component: {},
          users: [],
        },
      ],
    },
    {
      name: "Dr. Abas Sabouni",
      profileImage: `${sabouni}`,
      cardBackgroundImg: `${defaultBKG}`,
      about: [
        "Associate Professor of Electrical Engineering",
        "Wilkes University Department of Electrical Engineering and Physics",
      ],
      contact: { email: "abas.sabouni@wilkes.edu", phone: "570-408-4832" },
      projects: [
        { name: "Bioengineering", component: {}, users: [] },
        { name: "Biosensor design", component: {}, users: [] },
        { name: "Microwave Imaging", component: {}, users: [] },
        { name: "Antenna Design", component: {}, users: [] },
        { name: "Finite-element Analysis", component: {}, users: [] },
      ],
    },

    {
      name: "Dr. Amjad Nazzal",
      profileImage: `${nazzal}`,
      cardBackgroundImg: `${defaultBKG}`,
      about: [
        "Associate Professor of Physics",
        "Wilkes University Department of Electrical Engineering and Physics",
      ],
      contact: { email: "amjad.nazzal@wilkes.edu", phone: "570-408-3276" },
      projects: [
        {
          name: "Semiconductor Design and Characterization",
          component: {},
          users: [],
        },
        { name: "Nano-Photonics", component: {}, users: [] },
        { name: "Density Functional Theory", component: {}, users: [] },
        { name: "Quantum Dots", component: {}, users: [] },
      ],
    },
    {
      name: "Dr. Ghazaleh Taherzadeh",
      profileImage: `${ghazaleh}`,
      cardBackgroundImg: `${defaultBKG}`,
      about: ["Assistant Professor of Computer Science"],
      contact: { email: "ghazaleh.taherzadeh@wilkes.edu", phone: "" },
      projects: [
        { name: "Bioinformatics", component: {}, users: [] },
        { name: "Data Science", component: {}, users: [] },
        { name: "Machine Learning", component: {}, users: [] },
      ],
    },
    // {
    //   name: "",
    //   profileImage: {},
    //   cardBackgroundImg: {},
    //   about: [""],
    //   contact: { email: "", phone: "" },
    //   projects: [{ name: "", component: {}, users: [] }],
    // },
  ];
}

export default userData;
