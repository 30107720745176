import { React, useState, useEffect } from "react";
import { Card, Nav } from "react-bootstrap";
import styled from "styled-components";
import { Route, Link } from "react-router-dom";
const ResearchCard = ({
  element,
  name,
  profile,
  background,
  about,
  projects,
  contact,
  users,
  setUsers,
  currentUser,
  setCurrentUser,
}) => {
  const [currentCardContent, setCurrentCardContent] = useState(
    <ul>
      {about.map((element) => {
        return <li>{element}</li>;
      })}
    </ul>
  );

  const clickHandler = () => {
    setCurrentUser(element);
    console.log("hi");
    console.log(currentUser);
  };

  const overlay =
    "radial-gradient(circle, rgba(218,225,239,0.02) 0%, rgba(88,96,233,0.5) 3%, rgba(27,17,67,0.7) 96%)";

  return (
    <CardContainer>
      <Card style={({ width: "30rem" }, { background: "rgb(40, 40, 40)" })}>
        <div
          className="background"
          style={{
            background: `${overlay}, url(${background})`,
          }}
        >
          <img className="profilePic" src={profile} alt="" />
          <h3>{name}</h3>
        </div>
        <Card.Header
          onClick={(e) => {
            switch (e.target.id) {
              case "about":
                console.log(currentCardContent);
                const aboutList = about.map((element) => {
                  return <li>{element}</li>;
                });
                setCurrentCardContent(<ul>{aboutList}</ul>);
                break;
              case "research":
                console.log(currentCardContent);
                const projectNameList = projects.map((element) => {
                  return <li>{element.name}</li>;
                });

                setCurrentCardContent(<ul>{projectNameList}</ul>);
                break;
              case "contact":
                console.log(currentCardContent);
                const contactInfo = (
                  <ul>
                    <li>Email: {contact.email}</li>
                    <li>Phone: {contact.phone}</li>
                  </ul>
                );
                setCurrentCardContent(contactInfo);
                break;
              default:
                const aboutList2 = about.map((element) => {
                  return <li>{element}</li>;
                });
                setCurrentCardContent(<ul>{aboutList2}</ul>);
            }
          }}
        >
          <Nav variant="tabs" defaultActiveKey="#first">
            <Nav.Item>
              <Nav.Link id="about" href="#first">
                About
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link id="research" href="#link">
                Research Projects
              </Nav.Link>
            </Nav.Item>
            <Nav.Item id="contact">
              <Nav.Link id="contact" href="#disabled">
                Contact
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Card.Body
          style={{
            height: "20rem",
          }}
        >
          <Card.Text
            style={{
              padding: "0",
              height: "14rem",
            }}
          >
            {currentCardContent}
          </Card.Text>

          {/* <Nav.Link to="./Researchers" href="./Researchers"> */}

          {/* </Nav.Link> */}
          <Link to="./Contact"></Link>
        </Card.Body>
      </Card>
    </CardContainer>
  );
};

//Styled
const CardContainer = styled.div`
  .button {
    padding: 0 0.2rem;
    border: none;
  }
  border-radius: 20px;
  overflow: hidden;
  margin: 2rem;
  flex-basis: 30rem;

  -webkit-box-shadow: 1px 2px 15px 5px rgba(0, 0, 0, 0.6);
  box-shadow: 1px 2px 15px 5px rgba(0, 0, 0, 0.6);

  ul {
    padding-left: 2rem;
  }

  li {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 2rem;
  }

  .background {
    height: 20rem;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .profilePic {
    border-radius: 50%;
    width: 12rem;
    height: 12rem;
    /* position: absolute;
    top: 10%;
    left: 25%; */
  }
`;

export default ResearchCard;
