import React from "react";
import styled from "styled-components";
import section1IMG from "../img/learningImg1.JPG";
import websRobots from "../img/WebsRobots.jpg";
import websRobots2 from "../img/WebsRobots2.jpg";
import { motion } from "framer-motion";

const OutreachOverview = () => {
  return (
    <Outreach>
      <h2>Giving Back </h2>
      <div className="description1">
        <img src={websRobots} alt="" />
        <p className=" text1">
          In addition to building the research and teaching opportunities at
          Wilkes University, a critical part of our mission is to use WARLOC for
          community outreach. Both the supercomputer itself and the surrounding
          lab environment provide unique opportunities to learners of all ages
          and backgrounds. Our team actively finds and develops unique resources
          which can leverage WARLOC to get people exciting about the future of
          computational research. The team already has plans to integrate WARLOC
          with Wilkes’ highly successful outreach programs including: The Women
          Empowered By Science (WEBS) summercamp for middle school girls, Upward
          Bound program for under-represent and financially disadvantaged high
          school students, and Passport to Science outreach to students from
          grades 4 to 9 (along with their parents)
        </p>
      </div>
      {/* <div className="description2">
        <p className=" text2">
          The team already has plans to integrate WARLOC with Wilkes’ highly
          successful outreach programs including: The Women Empowered By Science
          (WEBS) summercamp for middle school girls, Upward Bound program for
          under-represent and financially disadvantaged high school students,
          and Passport to Science outreach to students from grades 4 to 9 (along
          with their parents)
        </p>
        <img src={websRobots2} alt="" />
      </div> */}
    </Outreach>
  );
};

const Outreach = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 80vw;
  margin: auto;
  padding-top: 8rem;

  .description1 {
    padding-top: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    img {
      width: 40%;
    }
    p {
      padding-left: 3rem;
      width: 60%;
      text-align: left;
      padding-bottom: 2rem;
    }
  }

  .description2 {
    padding-top: 6rem;
    padding-bottom: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    img {
      width: 40%;
      min-width: 10%;
    }
    p {
      padding-right: 3rem;
      width: 60%;
      text-align: left;
      padding-bottom: 2rem;
    }
  }

  @media (max-width: 560px) {
    img {
      flex: 2 1 auto;
    }
  }
`;

export default OutreachOverview;
