import React from "react";

import background from "../img/research.jpg";

//Styled
import styled from "styled-components";
import { About, Description } from "../styles";
//Animations
import { motion } from "framer-motion";
import { fade } from "../animation";

//Scroll to top
import ScrollTop from "./ScrollTop";

const BannerSection = () => {
  return (
    <About2>
      <Description className="text">
        <motion.h2 className="letter">RESEARCH</motion.h2>
        <motion.p variants={fade}>
          “WARLOC is the new supercomputer in the College of Science and
          Engineering. It is the fastest computer in the region and enables
          state of the art scientific research through advanced data analysis
          and computer simulation.”
        </motion.p>
      </Description>
      <ScrollTop />
    </About2>
  );
};

//Styled Components

const About2 = styled(About)`
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  overflow: hidden;
  background: linear-gradient(
      261deg,
      rgba(6, 2, 82, 0.6982143199076505) 42%,
      rgba(50, 50, 122, 0.421) 67%,
      rgba(0, 212, 255, 0.47692580450148814) 100%
    ),
    url(${background});
  background-size: cover;
  background-position: center;

  .letter {
    font-size: 5rem;
    font-family: "WarlocFont", cursive;
    font-weight: normal;
    padding-right: 0.25rem;
    color: white;
    text-shadow: 1px 1px 2px black, 0 0 10px #0a93ee8d, 0 0 5px #0a93ee;
  }

  p {
    width: 60vw;
    text-shadow: 1px 1px 2px black, 0 0 10px #0a93ee8d, 0 0 5px #0a93ee;
  }
`;

export default BannerSection;
