import { createGlobalStyle } from "styled-components";

import WarlocFont from "./RetroStereoWide.ttf";

export default createGlobalStyle`
@font-face{
  font-family: 'WarlocFont';
  src:   
  url(${WarlocFont})  format('truetype');
}`;
