import { v4 as uuidv4 } from "uuid";

//Components
import NetworkSwitch from "./Components/ClusterComponents/NetworkSwitch";
import LargeMemory from "./Components/ClusterComponents/LargeMemoryNode";
import WorkStation from "./Components/ClusterComponents/WorkStationNodes";
import TeslaGPU from "./Components/ClusterComponents/TeslaGPU";
import DataStorage from "./Components/ClusterComponents/DataStorage";
import MasterControl from "./Components/ClusterComponents/MasterControl";
import PowerSupply from "./Components/ClusterComponents/PoweSupply";

function nodeData() {
  return [
    {
      name: "Network Switches (2)",

      component: <NetworkSwitch />,
      info: [
        "1G switch for management and connection to campus network",
        "10G switch for inter-node communication",
      ],
      active: true,
      id: uuidv4(),
      color: "#a340f3",
    },

    {
      name: "Large Memory Node(2)",
      component: <LargeMemory />,
      info: [
        "40 cores/node (2x Xeon Gold)",
        "1.5 TB of RAM/node",
        "For large datasets and highly detailed simulations.",
      ],
      active: false,
      id: uuidv4(),
      color: "#0004ff",
    },

    {
      name: "Workstation Nodes(4)",
      component: <WorkStation />,
      info: [
        "40 cores/node (2x Xeon Gold)",
        "384 GB of RAM/node",
        "8x Quadro P5000 GPUs/node",
        " For general calculations and virtual highperformance workstations",
      ],
      active: false,
      id: uuidv4(),
      color: "#32daf8",
    },
    {
      name: "Tesla GPU Node",
      component: <TeslaGPU />,
      info: [
        "40 cores (2x Xeon Gold)",
        "4x Tesla V100 nvlink GPUs",
        "Most powerful GPUs in the world",
        "Used for AI and specialized simulations",
      ],
      active: false,
      id: uuidv4(),
      color: "#0af363",
    },

    {
      name: "High Capacity Data Storage Node",
      component: <DataStorage />,
      info: [
        "40 cores (2x Xeon Gold)",
        "96 GB of RAM",
        "220 TB Storage (RAID6)",
        "Stores large datasets and models",
      ],
      active: false,
      id: uuidv4(),
      color: "#efff0f",
    },

    {
      name: "Master Control Node",
      component: <MasterControl />,
      info: [
        "32 cores (2x Xeon Gold)",
        "192 GB of RAM ",
        "100 TB Storage (RAID6)",
        "Controls the whole cluster, hosts software",
      ],
      active: false,
      id: uuidv4(),
      color: "#ff5b0f",
    },

    {
      name: "Power Supply",
      component: <PowerSupply />,
      info: ["200V", "3P", "60A Power"],
      active: false,
      id: uuidv4(),
      color: "#ff0000",
    },
  ];
}

export default nodeData;
