import React from "react";
import styled from "styled-components";
import section1IMG from "../img/learningImg1.JPG";
import studentVR from "../img/learningVR.JPG";
import { motion } from "framer-motion";

const LearningOverview = () => {
  return (
    <Learning>
      <h2>New Opportunities </h2>
      <div className="description1">
        <img src={studentVR} alt="" />
        <p className=" text1">
          In addition to handling challenging research computing tasks, WARLOC
          can simultaneously host up to 30 virtual machines. These virtual
          machines act as mini high-performance personal computers that students
          can use in their classes. Students simply log in from anywhere on
          campus from a laptop or tablet and can run simulations, analyze data,
          and build models. We can effectively create a state of the art
          computer lab instantaneously from anywhere on campus.
        </p>
      </div>
      <div className="description2">
        <p className=" text1">
          The massive 300 terabyte storage node allows WARLOC to hold massive
          datasets. In addition to research purposes, this resource can be used
          to house the data needed to teach classes in topics such as databases,
          data mining, and artificial intelligence, and data visualization.
        </p>
        <img src={section1IMG} alt="" />
      </div>
    </Learning>
  );
};

const Learning = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 80vw;
  margin: auto;
  padding-top: 8rem;

  .description1 {
    padding-top: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    img {
      width: 40%;
    }
    p {
      padding-left: 3rem;
      width: 60%;
      text-align: left;
      padding-bottom: 2rem;
    }
  }

  .description2 {
    padding-top: 6rem;
    padding-bottom: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    img {
      width: 40%;
      min-width: 10%;
    }
    p {
      padding-right: 3rem;
      width: 60%;
      text-align: left;
      padding-bottom: 2rem;
    }
  }

  @media (max-width: 560px) {
    img {
      flex: 2 1 auto;
    }
  }
`;

export default LearningOverview;
