import React from "react";

//Animations
import { motion } from "framer-motion";
import styled from "styled-components";

const WorkStationNodes = () => {
  return (
    <Workstation id="WorkStation">
      <WorkstationNodes>
        <WorkstationNodesCircle></WorkstationNodesCircle>
      </WorkstationNodes>
      <WorkstationNodes>
        <WorkstationNodesCircle></WorkstationNodesCircle>
      </WorkstationNodes>
      <WorkstationNodes>
        <WorkstationNodesCircle></WorkstationNodesCircle>
      </WorkstationNodes>
      <WorkstationNodes>
        <WorkstationNodesCircle></WorkstationNodesCircle>
      </WorkstationNodes>
      <WorkstationNodes>
        <WorkstationNodesCircle></WorkstationNodesCircle>
      </WorkstationNodes>
      <WorkstationNodes>
        <WorkstationNodesCircle></WorkstationNodesCircle>
      </WorkstationNodes>
      <WorkstationNodes>
        <WorkstationNodesCircle></WorkstationNodesCircle>
      </WorkstationNodes>
      <WorkstationNodes>
        <WorkstationNodesCircle></WorkstationNodesCircle>
      </WorkstationNodes>
    </Workstation>
  );
};

const Workstation = styled(motion.div)`
  background: #000000;
  height: 10%;
  width: 100%;

  border-left: 0px;
  border-right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  margin-top: 3%;
  box-shadow: 0 0 1px 1px #32daf8;
  cursor: pointer;
`;

const WorkstationNodes = styled(motion.div)`
  background: #000000;
  height: 106%;
  width: 15%;
  border: 1px solid #32daf8;

  display: flex;
  justify-content: center;
  box-shadow: 0 0 1px 1px #32daf8;
  pointer-events: none;
`;
const WorkstationNodesCircle = styled(motion.div)`
  background: #2ec6e0;
  height: 8px;
  width: 8px;
  border: 2px solid #32daf8;
  border-radius: 50%;
  position: relative;
  top: 20%;
  box-shadow: 0 0 2px 1px #32daf8;
  pointer-events: none;
`;

export default WorkStationNodes;
