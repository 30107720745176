import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}

body{
    /* background: #1b1b1b; */
    background: #120e25;
    font-family: 'Inter', sans-serif; 
    overflow-x: hidden;
}



html{
    @media (max-width: 1700px){
        font-size:75%;
    }
    @media (max-width: 1300px){
        font-size:70%;
         
    }
}

button{
    font-weight: bold;
    font-size: 1.1rem;
    font-family: 'Inter', sans-serif; 
    cursor: pointer;
    padding: 1rem 2rem;
    border: 2px solid#f5ea8a;
    background: transparent;
    color: white;
    transition: all 0.5s ease;
    &:hover{
        background: #3fb2ff;
        color: white;
    }
    
}

    h2{
        font-weight: lighter;
        font-size: 3.5rem;
    }
    h3{
        color: white;
        font-size:2.8rem;
    }

    h4{
        font-weight: bold;
        font-size: 2rem;
    }

    span{
        /* font-weight:bold; */
        /* color:#09B8F5; */

    }

    a{
        font-size: 1.1.rem;
    }

    p{
        padding: 3rem 0rem;
        color: #ccc;
        font-size: 1.4rem;
        line-height: 150%;
    }

`;

export default GlobalStyle;
