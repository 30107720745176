import React from "react";

import styled from "styled-components";

import ProteinBannerIMG from "../../img/ProteinBanner.png";
import Pic from "../../img/Picture1.png";

const ProteinFolding = () => {
  return (
    <>
      <ProteinBanner>
        <h2>Protein folding and small molecule design</h2>
      </ProteinBanner>
      <Research>
        <div className="description2">
          <img src={Pic} alt="people doing research"></img>
          <p>
            Proteins are fundamental building blocks of life. Do nearly all of
            the things needed to sustain life. Proteins must fold correctly to
            do their jobs. When protein folding goes wrong, many diseases arise.{" "}
          </p>

          <p>
            Proteins are very small and fold very fast. It is impossible to
            watch proteins fold in the lab fast enough and with enough detail.
            Instead we can use physics to simulate folding!
          </p>
        </div>
      </Research>
    </>
  );
};

const ProteinBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    padding: 0 5vw;
    color: white;
  }
  background: linear-gradient(
      90deg,
      rgba(21, 15, 100, 0.74) 43%,
      rgba(40, 26, 122, 0.74) 58%,
      rgba(6, 17, 33, 1) 100%
    ),
    url(${ProteinBannerIMG});
  background-size: cover;
  background-position: center;
  height: 30vh;
`;

const Research = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 5rem 0;
  width: 80vw;
  margin: auto;

  img {
    width: 40%;
    height: auto;
    flex: 0 1 auto;
  }

  .description {
    width: 50%;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 3 auto;
    p {
      text-align: left;
      padding-bottom: 2rem;
    }
  }
  .description2 {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 80vw;
    }

    .smallPic {
      width: 40vw;
    }
    p {
      font-size: 2rem;
      text-align: left;
      padding: 0 10% 5% 10%;
    }
  }
`;

export default ProteinFolding;
