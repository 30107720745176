import React from "react";

import warloc2 from "../img/warloc_vector2.svg";
import background from "../img/backgroundGrid.svg";
//Styled
import styled from "styled-components";
import { About, Description, Image, Hide } from "../styles";
//Animations
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim } from "../animation";

//Scroll to top
import ScrollTop from "./ScrollTop";

const BannerSection = () => {
  return (
    <About2>
      <ScrollTop />
      <Description>
        <motion.div>
          <Hide>
            <motion.h2 variants={titleAnim}>
              <span className="letter">W</span>ilkes
            </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>
              <span className="letter">A</span>ccelerated{" "}
            </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>
              <span>
                <span className="letter">R</span>esearch
              </span>
            </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>
              <span>
                <span className="letter">L</span>earning
              </span>{" "}
              and
            </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>
              <span>
                <span className="letter">O</span>utreach
              </span>
            </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>
              <span className="letter">C</span>luster
            </motion.h2>
          </Hide>
        </motion.div>
        <motion.p variants={fade}>
          “WARLOC is the new supercomputer in the College of Science and
          Engineering. It is the fastest computer in the region and enables
          state of the art scientific research through advanced data analysis
          and computer simulation.”
          {/* WARLOC is a high-performance computer cluster funded by a major
          Research Instrumentation grant awarded to Wilkes University from the
          National Science Foundation. */}
        </motion.p>
      </Description>
      <Image>
        <motion.img variants={photoAnim} src={warloc2} alt="cluster" />
      </Image>
    </About2>
  );
};

//Styled Components

const About2 = styled(About)`
  background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 26%,
      rgba(9, 9, 121, 0) 68%
    ),
    url(${background});

  background-size: cover;

  .letter {
    font-family: "WarlocFont", cursive;
    font-weight: normal;
    padding-right: 0.25rem;
    color: white;
    text-shadow: 1px 1px 2px black, 0 0 10px #0a93ee8d, 0 0 5px #0a93ee;
  }

  @media (max-width: 1300px) {
    display: flex;
    h2,
    p {
      text-align: left;
      padding-left: 5vw;
    }
    img {
      width: 100%;
      height: auto;
    }

    @media (max-width: 540px) {
      display: flex;
      h2 {
        padding: 0;
        margin: 0;
        width: 250px;
        border: 0;
      }
      p {
        width: 90%;
      }

      img {
        width: 40%;
        position: absolute;
        top: 50vh;
        right: 1rem;
      }
    }
    @media (max-width: 320px) {
      img {
        width: 40%;
        position: absolute;
        top: 60vh;
        right: 0.1rem;
      }
    }
  }
`;

export default BannerSection;
