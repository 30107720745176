import React from "react";

import styled from "styled-components";

import Banner from "../../img//TDABanner.jpg";
import Arrow from "../../img/arrow.svg";
import MD from "../../img/MD.png";
import TopFingerprinting from "../../img/TopFingerprinting.png";
import Decomposition from "../../img/decomposition.png";
import Markovian from "../../img/markovian.png";

const ProteinFolding = () => {
  return (
    <>
      <ProteinBanner>
        <h2>Using topological data analysis to model protein folding</h2>
      </ProteinBanner>
      <Research>
        <div className="description2">
          <div className="imageContainer">
            <p>Molecular dynamics simulation</p>
            <img src={MD} alt="people doing research"></img>
            <img className="arrow" src={Arrow} alt="down arrow"></img>

            <p>Topological Fingerprinting</p>
            <img src={TopFingerprinting} alt="people doing research"></img>
            <img className="arrow" src={Arrow} alt="down arrow"></img>
            <p>Clustering and State Decomposition</p>
            <img src={Decomposition} alt="people doing research"></img>
            <img className="arrow" src={Arrow} alt="down arrow"></img>
            <p>Markovian state model</p>
            <img src={Markovian} alt="people doing research"></img>
          </div>
          <div className="listContainer">
            <ul>
              <li>
                Combining MD simulations with TDA we can build robust models of
                folding.
              </li>
              <li>
                We are currently applying this to intrinsically disordered
                proteins, where standard methods struggle.
              </li>
            </ul>
          </div>
        </div>
      </Research>
    </>
  );
};

const ProteinBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    padding: 0 5vw;
    color: white;
  }
  background: linear-gradient(
      90deg,
      rgba(21, 15, 100, 0.74) 43%,
      rgba(40, 26, 122, 0.74) 58%,
      rgba(6, 17, 33, 1) 100%
    ),
    url(${Banner});
  background-size: cover;
  background-position: center;
  height: 30vh;
`;

const Research = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 5rem 0;
  width: 80vw;
  margin: auto;

  img {
    width: 40%;
    height: auto;
    flex: 0 1 auto;
  }

  .description {
    width: 50%;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 3 auto;
    p {
      text-align: left;
      padding-bottom: 2rem;
    }
  }
  .description2 {
    width: 80vw;
    display: flex;

    align-items: center;
    justify-content: center;

    .imageContainer {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }

    img {
      width: 40vw;
    }

    p {
      font-size: 2rem;
      text-align: left;
      padding: 0 10% 0% 10%;
    }
  }

  .arrow {
    height: 10vh;
  }

  .listContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 2rem;
    font-size: 2rem;
    color: #ccc;
    border: 1px solid blue;
    border-radius: 10px;
  }
`;

export default ProteinFolding;
