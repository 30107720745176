import React from "react";
// import { Link } from "react-router-dom";

//Import Components
import Banner from "../Components/ResearchBanner";
import Overview from "../Components/ResearchOverview";

import Card from "../Components/ResearchCard";

//styles
import styled from "styled-components";
//Images

//Animations
import { motion } from "framer-motion";
//Scroll to top
import ScrollTop from "../Components/ScrollTop";

const ResearchPage = ({
  users,
  setUsers,
  currentCardContent,
  setCurrentCardContent,
  currentUser,
  setCurrentUser,
}) => {
  return (
    <ResearchContainer users={users}>
      <Banner />
      <Overview />
      <h2> Current Users</h2>
      <CardContainer>
        {users.map((element) => {
          return (
            <Card
              element={element}
              name={element.name}
              profile={element.profileImage}
              background={element.cardBackgroundImg}
              about={element.about}
              projects={element.projects}
              contact={element.contact}
              users={users}
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
              key={element.name}
            />
          );
        })}
      </CardContainer>
      <ScrollTop />
    </ResearchContainer>
  );
};

//styled components

const ResearchContainer = styled(motion.div)`
  h2 {
    text-align: center;
    font-size: 5rem;
    color: white;
  }
`;

const CardContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80vw;
  margin: auto;
  padding-top: 5rem;
`;

export default ResearchPage;
