import React from "react";
//import icons

import ControlledCarousel from "./Carousel";

//Styles
import styled from "styled-components";
import { About, Description } from "../styles";
//Animation
import { fade } from "../animation";
import { useScroll } from "./UseScroll";

const TeamSection = () => {
  const [element, controls] = useScroll();
  return (
    <Team
      transition={{ duration: 0.75 }}
      ref={element}
      variants={fade}
      animate={controls}
      initial="hidden"
    >
      <Description>
        <Info>
          <div className="description">
            <h2>The Team</h2>
            <p>
              The grant funding WARLOC was crafted by a multi-disciplinary team
              of faculty members from the Wilkes Integrated Science and
              Engineering (WISE) group with a common goal of integrating
              computational technology with teaching and research. The team was
              led by Henry J. Castejon, professor and chair of mechanical
              engineering, and included Del Lucent, associate professor of
              physics; Caroline Fortunato, assistant professor of biology; Bobak
              Karimi, assistant professor of environmental engineering and earth
              science; and Sofya Chepushtanova, assistant professor of math and
              computer science.
            </p>
          </div>
          <ControlledCarousel />
        </Info>
      </Description>
    </Team>
  );
};

//Styled Components

const Team = styled(About)``;

const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  .description {
    width: 50%;
  }

  .carousel {
    width: 50%;
  }
  @media (max-width: 1000px) {
    justify-content: center;
    .description {
      width: 100%;
    }
    .carousel-indicators {
      top: 110%;
    }
  }
`;

export default TeamSection;
