import React, { useState, useEffect } from "react";
//Global Style
import GlobalStyle from "./Components/GlobalStyle.js";
import GlobalFonts from "./Fonts/fonts";
//import Pages
import AboutUs from "./Pages/AboutUs";
import Details from "./Pages/TechDetails";
import ResearchPage from "./Pages/ResearchPage";
import LearningPage from "./Pages/LearningPage";
import Research from "./Pages/Research.js";
import Nav from "./Components/nav";
//Router
import { Switch, Route, useLocation } from "react-router-dom";
//Animation
import { AnimatePresence } from "framer-motion";
//import cluster tech data
import nodeData from "./TechData";
import userData from "./UserData";
import Outreach from "./Pages/Outreach.js";

function App() {
  const location = useLocation();

  //Create State
  const [popupStatus, setPopupStatus] = useState(false); //Cluster Detail
  const [popupDefaultStatus, setPopupDefaultStatus] = useState(true); //Cluster Detail
  const [nodes, setNodes] = useState(nodeData()); //Tech Node Info
  const [currentSelection, setCurrentSelection] = useState(nodes[0]); //Specific Node info
  const [users, setUsers] = useState(userData());
  const [currentUser, setCurrentUser] = useState(); //Set Current Researcher to display
  const [researchMenu, setResearchMenu] = useState(true);

  useEffect(() => {
    console.log(currentUser);
    setCurrentUser(currentUser);
    console.log("ugh");
  }, [currentUser]);

  return (
    <div className="App">
      <GlobalFonts />
      <GlobalStyle />

      <Nav />

      <Switch location={location} key={location.pathname}>
        <Route path="/" exact>
          <AboutUs />
        </Route>

        <Route path="/Research" exact>
          <ResearchPage
            users={users}
            setUsers={setUsers}
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
          />
        </Route>
        <Route path="/Learning" exact>
          <LearningPage />
        </Route>
        <Route path="/Details">
          <Details
            popupStatus={popupStatus}
            setPopupStatus={setPopupStatus}
            nodes={nodes}
            setNodes={setNodes}
            currentSelection={currentSelection}
            setCurrentSelection={setCurrentSelection}
            popupDefaultStatus={popupDefaultStatus}
            setPopupDefaultStatus={setPopupDefaultStatus}
          />
        </Route>

        <Route path="/Outreach">
          <Outreach />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
