import React from "react";
import styled from "styled-components";

const TechPopUp = ({
  currentSelection,
  setCurrentSelection,
  nodes,
  setNodes,
  setPopupStatus,
  setPopupDefaultStatus,
}) => {
  return (
    <Popup>
      <h1 style={{ color: ` ${currentSelection.color}` }}>
        {currentSelection.name}
      </h1>
      <div className="Constr">
        <NodeContainer>{currentSelection.component}</NodeContainer>
      </div>
      <ul>
        {currentSelection.info.map((element) => {
          return <li>{element}</li>;
        })}
      </ul>
      <h2
        className="exit"
        style={{ color: ` ${currentSelection.color}`, fontSize: "2rem" }}
        onClick={(e) => {
          setPopupStatus(false);
          setPopupDefaultStatus(true);
        }}
      >
        X
      </h2>
    </Popup>
  );
};

export default TechPopUp;

const Popup = styled.div`
  .exit {
    position: absolute;
    top: 1%;
    right: 2%;
    cursor: pointer;
  }
  height: 60vh;
  width: 50vw;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.678);
  color: white;
  /* border: 3px solid; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .Constr {
    height: 90px;
    width: 160px;
    display: flex;
    justify-content: center;

    overflow: hidden;
    transform: scale(1.5);
  }
  h1 {
    padding-bottom: 5rem;

    color: white;
    text-align: center;
  }
  ul {
    margin-top: 2rem;
    font-size: 1.6rem;
    color: white;
    padding: 0 5rem;
    text-align: left;
    width: 80%;
  }
`;
const NodeContainer = styled.div`
  height: 550px;
  width: 160px;
`;
