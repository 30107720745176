import React from "react";
import styled from "styled-components";
import { About } from "../styles";
import Toggle from "./Toggle";
import { AnimateSharedLayout } from "framer-motion";
import { useScroll } from "./UseScroll";
import { scrollReveal } from "../animation";
import groupImage from "../img/group.jpg";

const FaqSection = () => {
  const [element, controls] = useScroll();

  return (
    <Faq
      transition={{ duration: 0.75 }}
      ref={element}
      variants={scrollReveal}
      animate={controls}
      initial="hidden"
    >
      <div className="Container">
        <div className="contactInfo">
          <h2>Contact Us:</h2>
          <ul>
            <h1>System Administrator:</h1>
            <li>Joe Gubbiotti</li>
            <li>Josepht.Gubbiotti@wilkes.edu</li>
            <li>570-408-7852</li>
          </ul>
        </div>

        <div className="groupPic">
          <p>hi</p>
        </div>
      </div>
      {/* <AnimateSharedLayout>
        <Toggle title="Question">
          <div className="question">
            <div className="answer">
              <p>Lorem ipsum dolor sit amet.</p>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Cumque, adipisci.
              </p>
            </div>
          </div>
        </Toggle>
        <Toggle title="Question">
          <div className="question">
            <div className="answer">
              <p>Lorem ipsum dolor sit amet.</p>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Cumque, adipisci.
              </p>
            </div>
          </div>
        </Toggle>
        <Toggle title="Question">
          <div className="question">
            <div className="answer">
              <p>Lorem ipsum dolor sit amet.</p>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Cumque, adipisci.
              </p>
            </div>
          </div>
        </Toggle>
      </AnimateSharedLayout> */}
    </Faq>
  );
};

//Styled
const Faq = styled(About)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50vh;

  .Container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 80vh;
  }

  .contactInfo {
    h2 {
      padding-bottom: 2rem;
    }
    h1 {
      font-size: 2rem;
    }
    ul {
      font-size: 1.5rem;
      list-style: none;
    }
    width: 40vw;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .groupPic {
    width: 60vw;
    height: 80vh;
    background: linear-gradient(
        261deg,
        rgba(6, 2, 82, 0.301) 42%,
        rgba(50, 50, 122, 0) 67%,
        rgba(0, 213, 255, 0) 100%
      ),
      url(${groupImage});
    background-size: cover;
    background-position: center;
  }
`;

export default FaqSection;
