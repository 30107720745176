import React from "react";
// import { Link } from "react-router-dom";
import Banner from "../Components/LearningBanner";
import Overview from "../Components/LearningOverview";
//styles
import styled from "styled-components";
//Images

//Animations
import { motion } from "framer-motion";
// import {
//   sliderContainer,
//   slider,
//   pageAnimation,
//   fade,
//   fade2,
//   photoAnim,
//   lineAnim,
//   movieContainer,
// } from "../animation";

//Scroll to top
import ScrollTop from "../Components/ScrollTop";

const LearningPage = () => {
  return (
    <LearningContainer initial="hidden" animate="show" exit="exit">
      <Banner />
      <Overview />

      <ScrollTop />
    </LearningContainer>
  );
};

//Styled Components
const LearningContainer = styled(motion.div)`
  h2 {
    font-size: 5rem;
    color: white;
  }
`;

export default LearningPage;
