import React from "react";

//Styled
import styled from "styled-components";
import { About, Description } from "../styles";
//Animations
import { motion } from "framer-motion";
import { fade } from "../animation";
import background from "../img/learning.jpg";

//Scroll to top
import ScrollTop from "./ScrollTop";

const BannerSection = () => {
  return (
    <About2>
      <ScrollTop />
      <Description className="text">
        <motion.h2 className="letter">Learning</motion.h2>
        <motion.p variants={fade}>
          “Being able to use the cluster allows far more responsive and
          efficient feedback than we would otherwise have access to. With our
          results being readily available at almost a moments notice, no time is
          wasted in our ability to gain a deeper understanding of our current
          problems and ideas allowing for much more work to be completed.
          Overall, this opportunity granted to us by our access to this cluster
          has been incredibly satisfying and I am grateful to play a part in the
          whole process. “ Cordell Siggins, Junior, Physics and Mathematics
          Double Major
        </motion.p>
      </Description>
    </About2>
  );
};

//Styled Components

const About2 = styled(About)`
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  overflow: hidden;
  background: linear-gradient(
      261deg,
      rgba(6, 2, 82, 0.6982143199076505) 42%,
      rgba(50, 50, 122, 0.421) 67%,
      rgba(0, 212, 255, 0.47692580450148814) 100%
    ),
    url(${background});
  background-size: cover;
  background-position: center;

  .letter {
    font-size: 5rem;
    font-family: "WarlocFont", cursive;
    font-weight: normal;
    padding-right: 0.25rem;
    color: white;
    text-shadow: 1px 1px 2px black, 0 0 10px #0a93ee8d, 0 0 5px #0a93ee;
  }

  p {
    width: 60vw;
    text-shadow: 1px 1px 2px black, 0 0 10px #0a93ee8d, 0 0 5px #0a93ee;
  }
`;
export default BannerSection;
