import React from "react";
//import icons

import learning from "../img/learning.svg";
import learning2 from "../img/warloc_learning.svg";
import outreach from "../img/outreach.svg";
import outreach2 from "../img/warloc_outreach.svg";
import research from "../img/research.svg";
import background from "../img/grid.svg";
import research2 from "../img/warloc_research.svg";
//Styles
import styled from "styled-components";
import { About, Description } from "../styles";
//Animation
import { useScroll } from "./UseScroll";
import { fade } from "../animation";

const GoalSection = () => {
  const [element, controls] = useScroll();
  return (
    <Services
    // transition={{ duration: 0.75 }}
    // ref={element}
    // variants={fade}
    // animate={controls}
    // initial="hidden"
    >
      <Description>
        <h2></h2>
        <Cards>
          <Card>
            <div className="icon">
              <img src={research2} alt="research" />
              <h3>Research</h3>
            </div>

            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae
              perferendis sunt explicabo dicta quam porro quas repellendus,
              voluptatum expedita nesciunt.
            </p>
            <button>More Details</button>
          </Card>
          <Card>
            <div className="icon">
              <img src={learning2} alt="learning" />
              <h3>Learning</h3>
            </div>
            <p>
              Classes that intergrate WARLOC are currently being taught. WARLOC
              can emulate an entire lab of 30 computers anywhere on campus.
              Virtually unlimited storage for computing projects.
            </p>
            <button>More Details</button>
          </Card>
          <Card>
            <div className="icon">
              <img src={outreach2} alt="outreach" />
              <h3>Outreach</h3>
            </div>
            <p>
              WARLOC will Enable computer science outreach for K-12. Students
              can explore STEM in Virtual Reality (VR) or interface with WARLOC
              through Raspberry Pi
            </p>
            <button>More Details</button>
          </Card>
        </Cards>
      </Description>
    </Services>
  );
};

//Styled Components
const Services = styled(About)`
  /* background: #282828; */
  background: radial-gradient(
      circle,
      rgba(9, 9, 121, 0) 14%,
      rgba(2, 0, 36, 1) 94%
    ),
    url(${background});

  background-size: cover;
  background-attachment: local;
  h2 {
    padding-bottom: 5rem;
    text-align: center;
  }
`;

const Cards = styled.div`
  display: flex;
  margin: auto;
  justify-content: space-evenly;
  flex-wrap: wrap;
  @media (max-width: 1300px) {
    justify-content: center;
  }
`;

const Card = styled.div`
  position: relative;
  overflow: hidden;
  background: #000000d5 94%;
  border-radius: 50px;
  border: solid 0.5px;
  margin: 0px 4px 4px 4px;
  /* border-image-slice: 1;
  border-width: 4px;
  border-image-source: linear-gradient(to left, #3a49d5, #d53a9d);
   */

  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 30rem;
  .icon {
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: 90%;
      height: 250px;
      margin: 0rem 0rem;
    }
    h3 {
      font-size: 2.5rem;
      margin-left: 1rem;
      padding-top: 2rem;
      padding-bottom: 0;
      font-family: "WarlocFont", cursive;
      font-weight: normal;
      color: white;
      text-shadow: 1px 2px 2px black, 0 0 20px #0a93ee, 0 0 5px #0a93ee;
    }
  }
  p {
    text-align: center;
    width: 70%;
    padding: 1rem 0.5rem 0.1rem 0.5rem;
    height: 30%;

    /* 
    margin-bottom: 0rem; */
  }

  button {
    margin-top: 1rem;
  }
  &:hover {
    margin: 0px;
    border: solid 2px #3a49d5;
    /* border-image-slice: 1;
    border-width: 4px; */
    /* border-image-source: linear-gradient(to left, #3a49d5, #d53a9d); */
    transform: scale(1.05);
    z-index: 2;
  }
  button {
    margin-bottom: 1rem;
  }
`;

export default GoalSection;
