import React from "react";
import { Link } from "react-router-dom";
//import icons

// import cluster from "../img/cluster.jpg";
import cluster from "../img/warlocAbout.jpg";

//Styles
import styled from "styled-components";
import { About, Description } from "../styles";
//Animation
import { useScroll } from "./UseScroll";
import { fade } from "../animation";

const AboutSection = () => {
  const [element, controls] = useScroll();
  return (
    <Services
      transition={{ duration: 0.75 }}
      ref={element}
      variants={fade}
      animate={controls}
      initial="hidden"
    >
      <Description>
        <h2 className="text-reflect">
          About <span className="warloc">WARLOC</span>
        </h2>
        <Info>
          <img src={cluster} alt="cluster"></img>

          <div className="description">
            <h3>Powerful Cluster Computing</h3>
            <p>
              WARLOC is a high-performance computer cluster funded by a major
              Research Instrumentation grant awarded to Wilkes University from
              the National Science Foundation.Purchase cost $386,000.
            </p>
            <p>
              Installed in SLC 171A in the Mark Engineering Center, Warloc is
              currently the most powerful academic computing resource in the
              region(NEPA?). Part of a shared facility for advanced
              computational research  To be used in conjunction with other
              computing resources in the Mark Engineering Center
            </p>

            <Link to="./Details">
              <button>More Details</button>
            </Link>
          </div>
        </Info>
        <Info>
          <div className="description2">
            <h3>Stimulate Institutional and Regional Growth</h3>
            <p>
              WARLOC is shared by scientists from various departments to
              increase research productivity, enchance learning of modern
              computational techniques for undergraduate students and contribute
              to numerous outreach activities in the college of science and
              engineering.
            </p>
          </div>
          <iframe
            title="clusterDescriptionVideo"
            src="https://www.youtube.com/embed/2-ZC9JOIaDc"
          ></iframe>
        </Info>
      </Description>
    </Services>
  );
};

//Styled Components
const Services = styled(About)`
  .warloc {
    font-size: 2.7rem;
    font-family: "WarlocFont", cursive;
    font-weight: normal;
    color: white;
    text-shadow: 1px 2px 2px black, 0 0 20px #0a93ee, 0 0 5px #0a93ee;
  }
  h2 {
    padding-bottom: 10rem;
    text-align: center;
  }
  width: 100%;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10rem;
  img {
    width: 40%;
    height: auto;
    flex: 0 1 auto;
  }

  .description {
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 3 auto;
    p {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    button {
      margin-top: 1.5rem;
    }
  }

  .description2 {
    padding: 5rem 5rem 5rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    flex: 0 0 2;
  }

  iframe {
    background: red;
    flex: 0 1 2;
    min-width: 60%;
  }

  @media (max-width: 1300px) {
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 5rem;

    p {
      text-align: left;
    }

    .description {
      padding-bottom: 0rem;
      p {
        margin-bottom: 0;
        padding-bottom: 0;
        width: 90%;
      }
    }

    .description2 {
      padding: 0rem;

      width: 90%;
      margin: auto;
    }

    iframe {
      background: #0eeb3e;
      width: 60%;
      height: 60%;
    }
  }

  @media (max-width: 560px) {
    img {
      flex: 2 1 auto;
    }
  }

  /*  
  @media (max-width: 560px) {
   
      img {
        flex: 2 1 auto;
  
  }  
  .description{
    padding-left: 2rem;
    padding-right: 2rem;
  }
    }   */
`;

export default AboutSection;
