import React from "react";

//Animations
import { motion } from "framer-motion";
import styled from "styled-components";

const DataStorage = () => {
  return (
    <DataStorageContainer id="DataStorage">
      <DataStorageNode>
        <DataStorageNodeRect></DataStorageNodeRect>
      </DataStorageNode>
      <DataStorageNode>
        <DataStorageNodeRect></DataStorageNodeRect>
      </DataStorageNode>
      <DataStorageNode>
        <DataStorageNodeRect></DataStorageNodeRect>
      </DataStorageNode>
      <DataStorageNode>
        <DataStorageNodeRect></DataStorageNodeRect>
      </DataStorageNode>
      <DataStorageNode>
        <DataStorageNodeRect></DataStorageNodeRect>
      </DataStorageNode>
      <DataStorageNode>
        <DataStorageNodeRect></DataStorageNodeRect>
      </DataStorageNode>
      <DataStorageNode>
        <DataStorageNodeRect></DataStorageNodeRect>
      </DataStorageNode>
      <DataStorageNode>
        <DataStorageNodeRect></DataStorageNodeRect>
      </DataStorageNode>
      <DataStorageNode>
        <DataStorageNodeRect></DataStorageNodeRect>
      </DataStorageNode>
      <DataStorageNode>
        <DataStorageNodeRect></DataStorageNodeRect>
      </DataStorageNode>
      <DataStorageNode>
        <DataStorageNodeRect></DataStorageNodeRect>
      </DataStorageNode>
      <DataStorageNode>
        <DataStorageNodeRect></DataStorageNodeRect>
      </DataStorageNode>
      <DataStorageNode>
        <DataStorageNodeRect></DataStorageNodeRect>
      </DataStorageNode>
      <DataStorageNode>
        <DataStorageNodeRect></DataStorageNodeRect>
      </DataStorageNode>
      <DataStorageNode>
        <DataStorageNodeRect></DataStorageNodeRect>
      </DataStorageNode>
      <DataStorageNode>
        <DataStorageNodeRect></DataStorageNodeRect>
      </DataStorageNode>
    </DataStorageContainer>
  );
};

const DataStorageContainer = styled(motion.div)`
  background: #000000;
  height: 15%;
  width: 100%;
  border: 2px solid #efff0f;
  margin-bottom: 1%;
  margin-top: 1%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 0px;
  align-items: center;
  cursor: pointer;
`;

const DataStorageNode = styled(motion.div)`
  background: #000000;
  height: 100%;
  width: 98%;
  border: 1px solid #efff0f;
  display: flex;
  pointer-events: none;
`;

const DataStorageNodeRect = styled(motion.div)`
  background: black;
  height: 40%;
  width: 50%;
  border: 1px solid #efff0f;
  position: relative;
  left: 45%;
  top: 15%;
  box-shadow: 0 0 2px 1px #efff0f;
  pointer-events: none;
`;

export default DataStorage;
