import React, { useEffect } from "react";
import styled from "styled-components";
import SideMenu from "../Components/ResearchSideMenu";

const Research = ({ researchMenu, setResearchMenu, users, currentUser }) => {
  console.log("Research");
  useEffect(() => {
    console.log(currentUser);

    console.log("ugh");
  }, [currentUser]);

  return (
    <Researcher>
      <div
        className={`researchContainer ${
          researchMenu ? "researchContainer-active" : ""
        }`}
      >
        <SideMenu
          researchMenu={researchMenu}
          setResearchMenu={setResearchMenu}
          users={users}
          name={currentUser.name}
          profile={currentUser.profileImage}
          background={currentUser.cardBackgroundImg}
          about={currentUser.about}
          projects={currentUser.projects}
          contact={currentUser.contact}
          key={currentUser.name}
        />
        <ResearchMain currentUser={currentUser}>
          {currentUser.projects.map((element) => {
            return (
              <div className="researchProjectDiv">{element.component}</div>
            );
          })}
        </ResearchMain>
      </div>
    </Researcher>
  );
};

const Researcher = styled.div`
  .researchContainer-active {
    margin-left: 26rem;
  }
`;

const ResearchMain = styled.div``;

export default Research;
