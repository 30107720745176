import React from "react";

//Animations
import { motion } from "framer-motion";
import styled from "styled-components";

const MasterControl = () => {
  return (
    <MasterControlContainer id="MasterControl">
      <MasterControlNodesTop>
        <MasterControlNode>
          <MCLine></MCLine>
          <MCRect></MCRect>
        </MasterControlNode>
        <MasterControlNode>
          <MCLine></MCLine>
          <MCRect></MCRect>
        </MasterControlNode>
        <MasterControlNode>
          <MCLine></MCLine>
          <MCRect></MCRect>
        </MasterControlNode>
        <MasterControlNode>
          <MCLine></MCLine>
          <MCRect></MCRect>
        </MasterControlNode>
      </MasterControlNodesTop>
      <MasterControlNodesBottom>
        <MCRectBottom></MCRectBottom>
      </MasterControlNodesBottom>
    </MasterControlContainer>
  );
};

const MasterControlContainer = styled(motion.div)`
  background: #000000;
  height: 14%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1%;
  margin-top: 1%;
  cursor: pointer;
`;

const MasterControlNode = styled(motion.div)`
  background: #000000;
  height: 100%;
  width: 50%;
  border: 1px solid #ff5b0f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const MasterControlNodesTop = styled(motion.div)`
  height: 50%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 1px 1px #ff5b0f;
  pointer-events: none;
`;
const MCLine = styled(motion.div)`
  height: 2%;
  width: 110%;
  background: #ff5b0f;
  border: 0.1px solid #ff5b0f;
  position: relative;
  top: 10%;
  box-shadow: 0 0 1px 0.1px #ff5b0f;
  pointer-events: none;
`;

const MCRect = styled(motion.div)`
  height: 25%;
  width: 40%;
  background: black;
  border: 1px solid #ff5b0f;
  position: relative;
  top: 25%;
  box-shadow: 0 0 1.5px 1px #ff5b0f;
  pointer-events: none;
`;

const MasterControlNodesBottom = styled(motion.div)`
  height: 60%;
  width: 100%;
  border: 1px solid #ff5b0f;

  border-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 1px 1px #ff5b0f;
  margin-bottom: 3%;
  pointer-events: none;
`;

const MCRectBottom = styled(motion.div)`
  height: 30%;
  width: 15%;

  border: 2px solid #ff5b0f;
  position: relative;
  left: 38%;
  box-shadow: 0 0 1.5px 1px #ff5b0f;
  pointer-events: none;
`;

export default MasterControl;
