import React from "react";

//Imgs
import background from "../img/backgroundGridReversed.svg";

//Cracs
import crac1 from "../img/crac1.svg";
import crac2 from "../img/crac2.svg";

//Cluster Components
import NetWorkSwitch from "../Components/ClusterComponents/NetworkSwitch";
import LargeMemoryNodes from "../Components/ClusterComponents/LargeMemoryNode";
import WorkStationNodes from "../Components/ClusterComponents/WorkStationNodes";
import TeslaGPU from "../Components/ClusterComponents/TeslaGPU";
import DataStorage from "../Components/ClusterComponents/DataStorage";
import MasterControl from "../Components/ClusterComponents/MasterControl";
import PowerSupply from "../Components/ClusterComponents/PoweSupply";

//Popup Components
import TechPopUp from "../Components/TechPopUp";
import DefaultPopUp from "../Components/DefaultPopup";

//Animations
import { motion } from "framer-motion";
import { pageAnimation } from "../animation";
import styled from "styled-components";
// import { Hide } from "../styles";

const Details = ({
  popupStatus,
  setPopupStatus,
  nodes,
  setNodes,
  currentSelection,
  setCurrentSelection,
  popupDefaultStatus,
  setPopupDefaultStatus,
}) => {
  return (
    <Page>
      <ContactStyle
        variants={pageAnimation}
        initial="hidden"
        animate="show"
        exit="exit"
      >
        <div className="flex">
          <p>10-ton CRAC</p>
          <img src={crac2} alt="" />
        </div>

        <div className="flex">
          <p>Cluster</p>
          <OuterGlow
            animate={{
              x: 0,
              y: 50,
              scale: 1.0,
              rotate: 0,
            }}
          >
            <Cluster>
              <ClusterInner>
                <NodeContainer
                  onClick={(e) => {
                    if (popupStatus === false) {
                      setPopupStatus(!popupStatus);
                      setPopupDefaultStatus(false);
                    }
                    switch (e.target.id) {
                      case "Network":
                        setCurrentSelection(nodes[0]);
                        break;

                      case "LargeMemory":
                        setCurrentSelection(nodes[1]);
                        break;

                      case "WorkStation":
                        setCurrentSelection(nodes[2]);
                        break;

                      case "Tesla":
                        setCurrentSelection(nodes[3]);
                        break;

                      case "DataStorage":
                        setCurrentSelection(nodes[4]);
                        break;

                      case "MasterControl":
                        setCurrentSelection(nodes[5]);
                        break;

                      case "PowerSupply":
                        setCurrentSelection(nodes[6]);
                        break;
                      default:
                    }
                  }}
                >
                  <NetWorkSwitch />
                  <NetWorkSwitch />
                  <LargeMemoryNodes />
                  <WorkStationNodes />
                  <WorkStationNodes />
                  <WorkStationNodes />
                  <WorkStationNodes />
                  <TeslaGPU />
                  <DataStorage />
                  <MasterControl />
                  <PowerSupply />
                </NodeContainer>
              </ClusterInner>
            </Cluster>
          </OuterGlow>
        </div>

        <div className="flex">
          <p>6-Ton CRAC</p>
          <img src={crac1} alt="" />
        </div>
        <div className={`popup ${popupStatus ? "active-popup" : ""}`}>
          <TechPopUp
            setPopupStatus={setPopupStatus}
            setPopupDefaultStatus={setPopupDefaultStatus}
            nodes={nodes}
            setNodes={setNodes}
            currentSelection={currentSelection}
            setCurrentSelection={setCurrentSelection}
          ></TechPopUp>
        </div>
        <div
          className={`popupDefault ${
            !popupDefaultStatus ? "active-popupDefaultHidden" : ""
          }`}
        >
          <DefaultPopUp
            currentSelection={currentSelection}
            nodes={nodes}
            setCurrentSelection={setCurrentSelection}
            setPopupStatus={setPopupStatus}
            setPopupDefaultStatus={setPopupDefaultStatus}
            setNodes={setNodes}
          ></DefaultPopUp>
        </div>
      </ContactStyle>
    </Page>
  );
};

const Page = styled(motion.div)`
  background: url(${background});
  background-size: cover;

  img {
    height: 550px;
    width: 160px;
    transform: scale(1.35);
  }

  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      padding: 0;
      margin: 0;
      transform: translateY(30px);
    }
  }
`;

const ContactStyle = styled(motion.div)`
  width: 100vw;
  height: 90vh;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 1300px) {
    padding: 2rem;
    font-size: 1rem;
  }
`;

const OuterGlow = styled(motion.div)`
  height: 550px;
  width: 160px;
  background-color: #fff;
  box-shadow: 0px -8px 20px 6px #444a4b;
  inset: 200px 0px 0px 0px;
`;

const Cluster = styled(motion.div)`
  background: black;
  height: 550px;
  width: 160px;
  /* border: 2px solid white; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClusterInner = styled(motion.div)`
  background: black;
  height: 95%;
  width: 88%;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 1px 1px white;
`;

const NodeContainer = styled(motion.div)`
  background: black;
  height: 96%;
  width: 93%;
  /* border: 2px solid white; */
  display: flex;
  flex-direction: column;
  margin-top: 11%;
`;

export default Details;
