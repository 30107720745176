import React from "react";

//Animations
import { motion } from "framer-motion";
import styled from "styled-components";

const PowerSupply = () => {
  return (
    <PowerSupplyContainer id="PowerSupply">
      <PSTop>
        <PSRect></PSRect>
        <PSRect></PSRect>
      </PSTop>
      <PSBottom>
        <PSLine></PSLine>
      </PSBottom>
    </PowerSupplyContainer>
  );
};

const PowerSupplyContainer = styled(motion.div)`
  background: #000000;
  height: 14%;
  width: 100%;
  border: 2px solid #ff0000;
  display: flex;
  flex-direction: column;
  margin-bottom: 1%;
  margin-top: 1%;
  box-shadow: 0 0 1.5px 1px#ff0000;
  cursor: pointer;
`;

const PSTop = styled(motion.div)`
  height: 40%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  pointer-events: none;
`;

const PSLine = styled(motion.div)`
  height: 5%;
  width: 80%;
  background: #ff0000;
  border: 1px solid #ff0000;
  position: relative;
  top: 10%;
  box-shadow: 0 0 1.5px 1px#ff0000;
  pointer-events: none;
`;

const PSBottom = styled(motion.div)`
  height: 60%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: left;
  align-items: left;
  pointer-events: none;
`;

const PSRect = styled(motion.div)`
  height: 30%;
  width: 15%;
  border: 2px solid #ff0000;
  position: relative;
  top: 30%;
  margin-right: 5px;
  position: relative;
  left: 30px;
  box-shadow: 0 0 1.5px 1px#ff0000;
  pointer-events: none;
`;

export default PowerSupply;
