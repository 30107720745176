import React, {useState} from "react";
//Bootstrap
import { Carousel } from "react-bootstrap";
import styled from "styled-components";



import caroline from "../img/team/caroline.jpg";
import castejon from "../img/team/castejon.jpg";
import Del from "../img/team/Del.jpg";
import karmini from "../img/team/karimi.jpg";
import sofya from "../img/team/sofya.jpg";

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item  >
        <SlideContainer>
          <div clasName = "slideWrapper">
        <img
           
          src={Del}
          alt="First slide"
           
        />
<div className = "captionWrap">
<h3>Dr. Del Lucent</h3>
                <p>Principal Investigator</p>
                </div>
       
        </div>       

         
        </SlideContainer>
      </Carousel.Item>
      <Carousel.Item>
      <SlideContainer>
          <div clasName = "slideWrapper">
        <img
           
          src={karmini}
          alt="third slide"
           
        />
<div className = "captionWrap">
<h3>Dr. Bobby Karimi</h3>
                <p>Co Principal Investigator</p>
                </div>
       
        </div>       

         
        </SlideContainer>
       
      </Carousel.Item>
      <Carousel.Item>
      <SlideContainer>
          <div clasName = "slideWrapper">
        <img
           
          src={sofya}
          alt="fourth slide"
           
        />
<div className = "captionWrap">
<h3>Dr. Sofya Chepushtanova</h3>
                <p>Co Principal Investigator</p>
                </div>
       
        </div>       

         
        </SlideContainer>
      </Carousel.Item>
      <Carousel.Item  >
        <SlideContainer>
          <div clasName = "slideWrapper">
        <img
           
          src={caroline}
          alt="fifth slide"
          
        />
<div className = "captionWrap">
        <h3>Dr. Caroline Fotunato </h3>
                <p>Co Principal Investigator</p>
                </div>
       
        </div>       

         
        </SlideContainer>
      </Carousel.Item>
      <Carousel.Item>
      <SlideContainer>
          <div clasName = "slideWrapper">
        <img
           
          src={castejon}
          alt="First slide"
           
        />
<div className = "captionWrap">
<h3>Dr. Henry Castejon</h3>
                <p>Principal Investigator</p>
                </div>
       
        </div>       

         
        </SlideContainer>
       
      </Carousel.Item>
     
    </Carousel>
  );
}

const SlideContainer = styled.div`
display: flex;
flex-direction:column;
align-items:center;
justify-content:center;
 
 img{
   width:20rem;
   border-radius: 50%;
   height:auto;
    
 }
align-items:center;
p{
  padding: 0;
  font-size: 1.4rem;
}
h3{
  font-size: 1.6rem;
}
.captionWrap{
  padding-top: 1rem;
 text-align: center;
}
`

export default ControlledCarousel;