import React from "react";
import styled from "styled-components";

const ResearchSideMenu = ({
  researchMenu,
  setResearchMenu,
  users,
  name,
  profile,
  background,
  about,
  projects,
  contact,
  key,
}) => {
  return (
    <MenuContainer>
      <div>
        <button
          onClick={() => {
            console.log(name);
            setResearchMenu(!researchMenu);
          }}
        ></button>
      </div>
      <div className={`menu ${researchMenu ? "menu-active" : ""}`}>
        <img src={profile} alt="" />
        <h3>{name}</h3>
        <p>{about}</p>
        <div>
          <ul>
            <li>Email: {contact.email}</li>
            <li>Phone: {contact.phone}</li>
          </ul>
        </div>

        {projects.map((element) => {
          return <div className="researchProjectDiv">{element.name}</div>;
        })}
      </div>
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  button {
    position: fixed;
    left: 0;
    z-index: 10;
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: fixed;
    top: 9vh;
    left: 0;
    width: 26rem;
    height: 91vh;
    background: #000000;
    color: white;
    box-shadow: 2px 2px 50px rgb(0, 0, 0);
    transition: all 0.5s ease;
    overflow-y: scroll;
    img {
      padding: 2rem;
    }
    h2 {
      color: white;
    }
    h3 {
      color: white;
    }
    ul {
      list-style: none;
    }
    .researchProjectDiv {
      border-top: 2px solid magenta;
      width: 100%;
      padding: 2.5rem;
      text-align: center;
    }

    opacity: 0;
    transform: translateX(-100%);
  }

  .menu-active {
    transform: translateX(0%);
    opacity: 1;
  }
`;

export default ResearchSideMenu;
