import React from "react";
//Animations
import { motion } from "framer-motion";
import styled from "styled-components";

const NetworkSwitch = () => {
  return (
    <NetworkSw id="Network">
      <NSwitch></NSwitch>
      <NSwitch></NSwitch>
      <NSwitch></NSwitch>
      <NSwitch></NSwitch>
      <NSwitch></NSwitch>
      <NSwitch></NSwitch>
      <NSwitch></NSwitch>
      <NSwitch></NSwitch>
      <NSwitch></NSwitch>
      <NSwitch></NSwitch>
      <NSwitch></NSwitch>
      <NSwitch></NSwitch>
      <NSwitch></NSwitch>
      <NSwitch></NSwitch>
      <NSwitch></NSwitch>
      <NSwitchEnd></NSwitchEnd>
    </NetworkSw>
  );
};

const NetworkSw = styled(motion.div)`
  background: #000000;
  height: 5%;
  width: 100%;
  border: 2px solid #a340f3;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 2%;
  box-shadow: 0 0 1px 0.5px #982bf1;
  cursor: pointer;
`;

const NSwitch = styled(motion.div)`
  background: #982bf1;
  height: 60%;
  width: 3%;
  border: 1px solid #982bf1;
  margin: 0px 1px 0px 1px;
  box-shadow: 0 0 1px 1px #982bf1;
  pointer-events: none;
`;
const NSwitchEnd = styled(motion.div)`
  background: #982bf1;
  height: 60%;
  width: 6%;
  border: 1px solid #982bf1;
  margin: 0px 1px 0px 1px;
  box-shadow: 0 0 1px 1px #982bf1;
  pointer-events: none;
`;

export default NetworkSwitch;
