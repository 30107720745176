import React from "react";

import styled from "styled-components";

const DefaultPopup = ({
  nodes,
  setCurrentSelection,
  popupStatus,
  setPopupStatus,
  popupDefaultStatus,
  setPopupDefaultStatus,
}) => {
  return (
    <DefaultPopupContainer>
      <h1>Technical Specifications</h1>
      <p>The two CRAC units provide air conditioning</p>
      <p>The Cluster contains the following features: </p>
      <ul>
        {nodes.map((element) => {
          return (
            <li style={{ color: `${element.color}` }}>
              {element.name}
              <div className="Constr">
                <NodeContainer
                  onClick={(e) => {
                    setPopupDefaultStatus(false);
                    setPopupStatus(true);
                    switch (e.target.id) {
                      case "Network":
                        setCurrentSelection(nodes[0]);

                        break;

                      case "LargeMemory":
                        setCurrentSelection(nodes[1]);
                        break;

                      case "WorkStation":
                        setCurrentSelection(nodes[2]);
                        break;

                      case "Tesla":
                        setCurrentSelection(nodes[3]);
                        break;

                      case "DataStorage":
                        setCurrentSelection(nodes[4]);
                        break;

                      case "MasterControl":
                        setCurrentSelection(nodes[5]);
                        break;

                      case "PowerSupply":
                        setCurrentSelection(nodes[6]);
                        break;

                      default:
                    }
                  }}
                >
                  {element.component}
                </NodeContainer>
              </div>
            </li>
          );
        })}
      </ul>
      <p>Click a node on the cluster to view more details.</p>
    </DefaultPopupContainer>
  );
};

export default DefaultPopup;

const DefaultPopupContainer = styled.div`
  height: 60vh;
  width: 50vw;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.829);
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .Constr {
    height: 90px;
    width: 160px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    transform: scale(0.5);
  }
  h1 {
    color: white;
    text-align: center;
  }

  p {
    padding: 0;
    text-align: left;
  }
  ul {
    list-style: none;
    margin-top: 0rem;
    font-size: 1rem;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  li {
    padding: 0rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const NodeContainer = styled.div`
  height: 550px;
  width: 160px;
`;
