import React from "react";
import styled from "styled-components";
import { Dropdown, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const Navv = () => {
  return (
    <StyledNav>
      <h1>
        <Link id="logo" to="./">
          WARLOC
        </Link>
      </h1>
      <ul>
        <li>
          <Link to="./">About Us</Link>
        </li>
        <li>
          <Link to="./Research">Research</Link>
        </li>
        <li>
          <Link to="./Learning">Learning</Link>
        </li>

        <li>
          <Link to="./Outreach">Outreach</Link>
        </li>
        <li>
          <Link to="./Details">Cluster Details</Link>
        </li>
      </ul>

      <Dropdown className="dropdown active">
        <Dropdown.Toggle id="dropdown-basic">
          <div className="burger line1"></div>
          <div className="burger line2"></div>
          <div className="burger line3"></div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="Menu">
          <Dropdown.Item href="/">About Us</Dropdown.Item>
          <Dropdown.Item href="./Research">Research</Dropdown.Item>
          <Dropdown.Item href="./Learning">Learning</Dropdown.Item>
          <Dropdown.Item href="./Outreach">Outreach</Dropdown.Item>
          <Dropdown.Item href="./Details">Cluster Details</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  flex-direction: row;

  width: 100vw;
  min-height: 2vh;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 10rem;
  position: sticky;
  top: 0;
  z-index: 10;

  background: #282828;
  a {
    color: white;
    text-decoration: none;
  }
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
  }
  #logo {
    font-size: 1.5rem;
    font-family: "WarlocFont", cursive;
    font-weight: normal;
    color: white;
    text-shadow: 1px 2px 2px black, 0 0 20px #0a93ee, 0 0 5px #0a93ee;
  }

  li {
    padding-left: 10rem;
    padding-top: 1rem;
    position: relative;
  }

  .dropdown {
    display: none;
    button {
      background: none;
      border: none;
      font-size: 0rem;
    }
    .Menu {
      padding: 1rem;
      font-size: 2rem;
      background: black;

      border-image-slice: 1;
      border-width: 2px;
      border-image-source: linear-gradient(to left, #3a49d5, #d53a9d);
    }
    .burger {
      height: 1px;
      width: 20px;
      background: white;
      margin: 5px 0px;
    }
  }

  @media (max-width: 1300px) {
    padding: 1rem;
    align-items: center;
    ul {
      padding: 2rem;
      justify-content: space-around;
      width: 100%;
    }
    li {
      padding: 0;
    }
    #logo {
      display: inline-block;
      margin: 2rem;
    }
  }

  @media (max-width: 800px) {
    ul {
      display: none;
    }
    .dropdown.active {
      display: flex;
      margin-right: 6rem;
    }
  }

  @media (max-width: 400px) {
    .dropdown.active .Menu {
      padding: 1rem;
      font-size: 2rem;
      background: blue;

      border-image-slice: 1;
      border-width: 2px;
      border-image-source: linear-gradient(to left, #3a49d5, #d53a9d);
      transform: scale(4);
    }
  }
`;

export default Navv;
