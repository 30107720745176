import React from "react";
//styles
import styled from "styled-components";
//Images
import Banner from "../Components/OutreachBanner";
import Overview from "../Components/OutreachOverview";
import grid from "../img/backgroundGrid.svg";
//Animations
import { motion } from "framer-motion";

//Scroll to top
import ScrollTop from "../Components/ScrollTop";

const Outreach = () => {
  return (
    <OutreachContainer>
      <Banner />

      <Overview />
      <ScrollTop />
      <h2
        style={{
          padding: "5rem 0rem",
          textAlign: "center",
          backgroundUrl: `${grid}`,
        }}
      >
        More coming soon!
      </h2>
    </OutreachContainer>
  );
};

//styled components

const OutreachContainer = styled(motion.div)`
  h2 {
    font-size: 5rem;
    color: white;
  }
`;

export default Outreach;
