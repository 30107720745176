import React from "react";
import styled from "styled-components";
import researchImg from "../img/Banner.mp4";
import studentPoster from "../img/studentsPoster.JPG";

const ResearchOverview = () => {
  return (
    <Container>
      <h2>Research Highlights</h2>
      <Research>
        <div className="description1">
          <img src={studentPoster} alt="" />
          <p>
            WARLOC will enable faculty and students to perform computational
            research previously impossible at Wilkes University. Faculty can use
            WARLOC to perform complex simulations, build sophisticated models,
            and analyze massive datasets. Some applications of faculty research
            enabled by this resource include: design of new drugs, studying the
            behavior of proteins that cause diseases such as Parkinson’s and
            Alzheimer’s, designing devices for early cancer detection,
            predicting landslides, designing new nano-materials and
            semiconductors.
          </p>
        </div>
        <div className="description2">
          <p>
            Students engage in intensive research projects during the summer and
            continue to perform their research year-round. Additionally, they
            have opportunities to learn how WARLOC works and how to build and
            operate a supercomputer. This sort of opportunity is incredibly rare
            in the academic world and gives Wilkes students a unique skillset
            that is in high demand.
          </p>

          <video className="videoTag" muted autoPlay loop>
            <source src={researchImg} type="video/mp4"></source>
          </video>
        </div>
      </Research>
      <h2></h2>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 8rem;
  text-align: center;
  width: 90vw;
  margin: auto;
  h2 {
    font-size: 5rem;
    color: white;
  }
`;

const Research = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 5vw;
  flex-wrap: wrap;

  .description1 {
    padding-top: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    img {
      width: 40%;
    }
    p {
      padding-left: 3rem;
      width: 60%;
      text-align: left;
      padding-bottom: 2rem;
    }
  }

  .description2 {
    padding-top: 6rem;
    padding-bottom: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    .videoTag {
      position: relative;
      width: 40%;
      min-width: 10%;
    }
    p {
      padding-right: 3rem;
      width: 60%;
      text-align: left;
      padding-bottom: 2rem;
    }
  }

  @media (max-width: 560px) {
    img {
      flex: 2 1 auto;
    }
  }
`;

export default ResearchOverview;
