import React from "react";

//Animations
import { motion } from "framer-motion";
import styled from "styled-components";

const TeslaGPU = () => {
  return (
    <TeslaGPUContainer id="Tesla">
      <TeslaNodeTop>
        <TeslaNodes>
          <TeslaNodesCircle></TeslaNodesCircle>
        </TeslaNodes>
        <TeslaNodes>
          <TeslaNodesCircle></TeslaNodesCircle>
        </TeslaNodes>
        <TeslaNodes>
          <TeslaNodesCircle></TeslaNodesCircle>
        </TeslaNodes>
        <TeslaNodes>
          <TeslaNodesCircle></TeslaNodesCircle>
        </TeslaNodes>
      </TeslaNodeTop>
      <TeslaNodeBottom>
        <TeslaNodeBottomLine></TeslaNodeBottomLine>
      </TeslaNodeBottom>
    </TeslaGPUContainer>
  );
};

const TeslaGPUContainer = styled(motion.div)`
  background: #000000;
  height: 9%;
  width: 100%;
  border-left: 0px;
  border-right: 0px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1%;
  margin-top: 1%;
  cursor: pointer;
  overflow: hidden;
`;
const TeslaNodes = styled(motion.div)`
  background: #000000;
  height: 100%;
  width: 50%;
  border: 2px solid #0af363;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 1px 1px #0af363;
  pointer-events: none;
`;

const TeslaNodeTop = styled(motion.div)`
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

const TeslaNodesCircle = styled(motion.div)`
  background: #0af363;
  height: 8px;
  width: 8px;
  border: 2px solid #0af363;
  border-radius: 50%;
  box-shadow: 0 0 2px 1px #0af363;
  pointer-events: none;
`;

const TeslaNodeBottom = styled(motion.div)`
  background: #000000;
  height: 50%;
  width: 100%;
  border: 2px solid #0af363;
  border-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 1px 1px #0af363;
  margin-bottom: 3%;
  pointer-events: none;
`;

const TeslaNodeBottomLine = styled(motion.div)`
  background: #0af363;
  height: 2%;
  width: 80%;
  border: 1px solid #0af363;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 2px 1px #0af363;
  pointer-events: none;
`;

export default TeslaGPU;
