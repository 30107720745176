import React from "react";

//Animations
import { motion } from "framer-motion";
import styled from "styled-components";

const LargeMemoryNodes = () => {
  return (
    <LargeMem id="LargeMemory">
      <LargeMemNode>
        <LargeMemLine></LargeMemLine>
        <LargeMemRect></LargeMemRect>
      </LargeMemNode>
      <LargeMemNode>
        <LargeMemLine></LargeMemLine>
        <LargeMemRect></LargeMemRect>
      </LargeMemNode>
    </LargeMem>
  );
};

const LargeMem = styled(motion.div)`
  background: #000000;
  height: 7%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
  margin-top: 2%;
  cursor: pointer;
`;

const LargeMemNode = styled(motion.div)`
  background: #000000;
  height: 100%;
  width: 100%;
  border: 2px solid #0004ff;
  display: flex;
  align-items: center;
  box-shadow: 0 0 2px 1px #0004ff;
  pointer-events: none;
`;

const LargeMemLine = styled(motion.div)`
  background: #000000;
  height: 110%;
  width: 1%;
  background: #0004ff;
  position: relative;
  left: 20%;
  box-shadow: 0 0 2px 1px #0004ff;
  pointer-events: none;
`;

const LargeMemRect = styled(motion.div)`
  background: #000000;
  height: 80%;
  width: 20%;
  border: 2px solid #0004ff;
  position: relative;
  left: 78%;
  box-shadow: 0 0 2px 1px #0004ff;
  pointer-events: none;
`;

export default LargeMemoryNodes;
