import React from "react";

import styled from "styled-components";

import Banner from "../../img//TDABanner.jpg";
import Pic from "../../img/mugnut.svg";

const ProteinFolding = () => {
  return (
    <>
      <ProteinBanner>
        <h2>Topological Data Analysis</h2>
      </ProteinBanner>
      <Research>
        <div className="description2">
          <img src={Pic} alt="people doing research"></img>
          <p>
            In topology two shapes are the same (i.e. homotopic) if they can be
            continuously transformed into each other without creating or
            removing any existing holes. “Shape” is based on number of “holes”.
          </p>
          <div className="listContainer">
            <p>TDA:</p>
            <ul>
              <li>
                Data can be viewed as noisy sampling from an underlying space
              </li>
              <li>Data has shape that can be quantified</li>
              <li> Coordinate invariance property</li>
              <li> Deformation invariance property</li>
              <li>Compressed representation of data</li>
            </ul>
          </div>
        </div>
      </Research>
    </>
  );
};

const ProteinBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    padding: 0 5vw;
    color: white;
  }
  background: linear-gradient(
      90deg,
      rgba(21, 15, 100, 0.74) 43%,
      rgba(40, 26, 122, 0.74) 58%,
      rgba(6, 17, 33, 1) 100%
    ),
    url(${Banner});
  background-size: cover;
  background-position: center;
  height: 30vh;
`;

const Research = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 5rem 0;
  width: 80vw;
  margin: auto;

  img {
    width: 40%;
    height: auto;
    flex: 0 1 auto;
  }

  .description {
    width: 50%;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 3 auto;
    p {
      text-align: left;
      padding-bottom: 2rem;
    }
  }
  .description2 {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 80vw;
    }

    .smallPic {
      width: 40vw;
    }
    p {
      font-size: 2rem;
      text-align: left;
      padding: 0 10% 5% 10%;
    }
  }

  .listContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 2rem;
    font-size: 2rem;
    color: #ccc;
    border: 1px solid blue;
    border-radius: 10px;
  }
`;

export default ProteinFolding;
